import { render, staticRenderFns } from "./ClientDossier.vue?vue&type=template&id=796c2627&scoped=true&"
import script from "./ClientDossier.js?vue&type=script&lang=js&"
export * from "./ClientDossier.js?vue&type=script&lang=js&"
import style0 from "./ClientDossier.vue?vue&type=style&index=0&id=796c2627&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "796c2627",
  null
  
)

export default component.exports