import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {
        return {
            pageTitle: "Liste des dossiers",

            vuetable: {
                moreParams: {},
                fields: [
                    {
                        name: 'nom_dossier',
                        title: 'Dossier',
                        sortField: 'nom_dossier',
                        dataClass: 'text-left',
                    },
                    {
                        name: 'nom_client',
                        title: 'Nom',
                        sortField: 'nom_client',
                        dataClass: 'text-left'
                    },

                    {
                        name: 'total_debit',
                        title: 'DEBIT',
                        width: '150px',
                        sortField: 'total_debit',
                        dataClass: 'text-right'
                    },
                    {
                        name: 'total_credit',
                        title: 'CREDIT',
                        width: '150px',
                        sortField: 'total_credit',
                        dataClass: 'text-right'
                    },
                    {
                        name: 'total_solde',
                        title: 'SOLDE',
                        width: '150px',
                        sortField: 'total_solde',
                        dataClass: 'text-right'
                    },

                    {
                        name: 'actions',
                        width: "120px",
                        dataClass: "text-center"
                    }

                ],
                sortOrder: [
                    { field: 'nom_client', direction: 'asc' }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars fa-sm',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },

            motCle: "",
            crudform: {
                id: "",
                id_client: this.$route.params.id_client,
                nom_client:'',
                nom_dossier: "",
            },
            crudmodaltitle: "Nouveau dossier",
            tab_client:[],

        }
    },
    watch: {
        $route(to, from) {
            console.log('valeur to',to);
            console.log('valeur from',from);
            if (to.name==='ClientDossierAvecID'){
                this.getClient(from.params.id);

            }

            if (to.name==='ClientDossier'){
                this.getClient(0);
            }
        },
    },
    methods: {


        getClient(id_client) {
            let critere ='';
            let filter='';
            this.vuetable.moreParams.criteriacol = critere;
            this.vuetable.moreParams.filter = filter;
            axios.get(this.BASE_URL + "/clients/getall/").then(response => {
                    console.log('Résultat ===>', response.data);
                    console.log('valeur _d_client',id_client);
                    this.tab_client = response.data;
                    if (parseInt(id_client) > 0) {
                        critere = 'nom_client';
                        this.tab_client.forEach(value => {
                            if (value.id == id_client) {
                                filter = value.nom_client;
                                this.vuetable.moreParams.criteriacol = critere;
                                this.vuetable.moreParams.filter = filter;
                            }
                        });
                    }
                    Vue.nextTick(() => this.$refs.vuetable.refresh());
                })
        },


        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        printElement(id){
            window.open(this.BASE_URL + "/clientdossierdetail/imprimerelement/" + id, '_blank');
        },
        editRow(id) {
            this.$router.push('/client/dossier/detail/'+id);
        },
        onRowClass(dataItem, index) {
            if (index == "") console.log("");
            return  (dataItem.nom_dossier === 'TOTAL') ? 'bg-dark' :'';
        },

        deleteRow(rowData) {
            //alert("You clicked delete on" + JSON.stringify(rowData));
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Supprimer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        axios.post(that.BASE_URL + "/clientdossierdetail/delete/" + rowData.id).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log(response.data.message);
                                that.setFilter();
                                Vue.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })

        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.criteriacol = this.criteriacol;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.vuetable.moreParams.filter = this.motCle;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {

            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        openModal() {
            this.$bvModal.show("crudmodal");
        },
        closeModal() {
            this.$bvModal.hide("crudmodal");
        },
        addRow() {
            this.crudmodaltitle = "Nouveau dossier";
            this.crudform = {
                id: "",
                id_client: "",
                nom_dossier: "",
            };
            this.openModal();
        },
        onSubmit() {
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = that.crudform.id == '' ? that.BASE_URL + "/clientdossier/addaction" : that.BASE_URL + "/clientdossier/editaction";
                        axios.post(link, $("#formulaire").serialize()).then(function(response) {
                            console.log(response);
                            setTimeout(()=>{
                                that.editRow(response.data.id_client_dossier)
                            },500)
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                that.closeModal();
                                that.setFilter();
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                    // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },


    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        this.motCle = "";
        this.criteriacol = "";
    },
    mounted: function() {

        console.log(process);
        console.log(process.env);
        console.log(process.env.STRIPE_TOKEN);
        this.getClient(this.$route.params.id_client);
    }
}
